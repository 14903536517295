import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";

import Parse from "../../components/Parse";

const Container = styled.main`
  width: 1299px;
  display: flex;
  flex-direction: column;
  row-gap: 108px;
  @media (max-width: 1024px) {
    width: 100%;
    padding: 35px 0 0;
    row-gap: 0;
  }
`
const TitleContainer = styled.div`
  margin-top: 14vh;
  text-align: center;
  font-size: 2vw;
  font-weight: 700;
  letter-spacing: .3vw;
  @media (max-width: 1024px) {
    font-size: 7vw;
  }
`
const Date = styled.div`
  margin-top: 3vh;
  color: #900000;
  text-align: center;
  font-size: 1vw;
  font-weight: 700;
  letter-spacing: .1vw;
  @media (max-width: 1024px) {
    font-size: 5vw;
  }
`
const Content = styled.div`
  margin-top: 10vh;
  margin-left: 7vw;
  margin-right: 7vw;
  padding-top: 3vh;
  padding-bottom: 3vh;
  padding-left: 3vw;
  padding-right: 3vw;
  border-top: solid 1px black;
  border-bottom: solid 1px black;
  white-space: break-spaces;
  font-size: 1.4vw;
  letter-spacing: .1vw;
  @media (max-width: 1024px) {
    margin-top: 3vh;
    font-size: 3.7vw;
  }
`
const Adress = styled.div`
  margin-top: 10vh;
  margin-left: 7vw;
  margin-right: 7vw;
  font-size: 1.4vw;
  letter-spacing: .1vw;
  &>:nth-child(1){
    padding: 1vw;
    background-color: rgba(128, 128, 128, 0.5);
  }
  &>:nth-child(2){
    padding: .5vw;
    padding-right: 1vw;
    text-align: right;
  }
  @media (max-width: 1024px) {
    margin-top: 3vh;
    font-size: 3vw;
  }
`
const Tel = styled.div`
  margin-top: 10vh;
  margin-left: 7vw;
  margin-right: 7vw;
  font-size: 1.4vw;
  letter-spacing: .1vw;
  &>:nth-child(1){
    padding: 1vw;
    background-color: rgba(128, 128, 128, 0.5);
  }
  &>:nth-child(2){
    padding: .5vw;
    padding-right: 1vw;
    text-align: right;
  }
  @media (max-width: 1024px) {
    margin-top: 3vh;
    font-size: 3vw;
  }
`
const BackButton = styled.button`
  margin-top: 10vh;
  font-size: 1vw;
  @media (max-width: 1024px) {
    margin-top: 7vh;
    font-size: 3vw;
  }
`

const recruits = [
  {no: 167, title: "주간 요양보호사 구인", company: "백마효요양원", date: "채용시까지", adress: "경기도 고양시 일산동구 경의로 315, 설촌상가5층 (마두동)", tel: "070-4377-3609",
    content: `/케어포프로그램 사용자 우대
/어르신케어 및 배식, 실내외 청소
/경력1년이상
/월급 210만
/근무시간: 09:00-18:00
/2개월근로계약 후 상용직전환검토
/백마역인근
/중식비포함`
  },
  {no: 166, title: "요양보호사 구인합니다.", company: "가족사랑복지센터", date: "2025-01-25", adress: "경기도 김포시 월곶면 애기봉로7번길 11", tel: "031-980-5975",
    content: `- 김포시 월곶면  군하리  재가요양보호사 구인합니다.
- 일상생활 지원서비스
- 근무시간: 09:00~12:00 주5일근무
- 급      여: 시급 12,500(기본 시급 9,860원+주휴수당+기타수당) +차량유지비 지원
- 자차 가능자 

* 실제 서비스 제공 기간에 따라 임금을 계산하여 지급하는 형태로 채용 시 근로자마다 주 소정 근로 시간이나 임금, 근무(예정)지 등에 차이가 있을 수 있음 *`
  },
  {no: 165, title: "[김포 감정동 북변동] 3등급 할머니 방문요양보호사 모집 합니다.", company: "예은방문요양센터", date: "채용시까지", adress: "경기도 김포시 봉화로167번길 35-9, 북변건영아파트 (감정동, 북변건영아파트)", tel: "031-999-0957",
    content: `- 3등급  어르신

- 시간 :  월~토  / 09-12시 또는 10-13시 / 주6회

- 식사지원, 말벗, 가사지원, 신체활동 지원, 병원동행 등 

- 북변동  북변건영아파트 

- 시급 9860 + 수당포함 = 12500원 

- 문의 : 031-989-0690`
  },
  {no: 164, title: "장기동 재가요양보호사 모집", company: "정다운재가노인복지센터", date: "2025-02-12", adress: "경기도 김포시 옹주물로 30, 김포한강어린이집 인근(김포대로1237번길) (장기동)", tel: "031-980-5974",
    content: `- 대상자: 5등급 할머님
- 담당업무: 가사, 일상생생활 함께하기 위주로 하기
- 근무시간: 09:00~12:00(시간 조정 가능)/ 주 5일
- 급     여: 시급 13,000원(기본시급 9,860원+주휴수당+기타수당)
*** 기저귀 케어 X

* 실제 서비스 제공 기간에 따라 임금을 계산하여 지급하는 형태로 채용 시 근로자마다 주 소정 근로 시간이나 임금,근무(예정)지 등에 차이가 있을 수 있음."`
  },
]

function Recruit(){
  const param = useParams().id;
  const navigate = useNavigate()
  const [isArticle, setIsArticle] = useState({})
  const [isArticleList, setIsArticleList] = useState([])
  const [isCat, setIsCat] = useState()

  useEffect(()=>{
    window.scrollTo(0, 0)
    axios.get(process.env.REACT_APP_API_URL+"research/"+param)
    .then(res=>{
      setIsArticle({
        researchId: res.data.researchId,
        category: res.data.category,
        createdAt: res.data.createdAt.slice(0,4)+"."+res.data.createdAt.slice(5,7)+"."+res.data.createdAt.slice(8,10),
        title: res.data.title,
        subTitle: res.data.subResearchResponseDtos[0].subContent, //카테고리, 부제목
        videoUrl: res.data.videoUrl,
        imageUrl: res.data.imageUrl,
        content: res.data.content,
        subImageUrl1: res.data.subResearchResponseDtos[0].subImageUrl,
        subContent1: res.data.subResearchResponseDtos[1].subContent,
        subImageUrl2: res.data.subResearchResponseDtos[1].subImageUrl,
        subContent2: res.data.subResearchResponseDtos[1].subTitle,
        tag: res.data.subResearchResponseDtos[0].subTitle,
      })
      axios.get(process.env.REACT_APP_API_URL+"research/category/"+res.data.category)
      .then(res=>setIsArticleList(res.data))
      .catch(err=>console.log(err))
    })
    .catch(err=>console.log(err))
  },[param])

  useEffect(()=>{
    // axios.get("https://dog.ceo/api/breeds/image/random")
    axios.get("https://api.thecatapi.com/v1/images/search")
    .then(res=>setIsCat(res.data[0].url))
  },[])

  return(
    <Container>
      {recruits.map(recruit=>(
        recruit.no==param?
        <div>
          <TitleContainer>{recruit.title}</TitleContainer>
          <Date>접수마감: {recruit.date}</Date>
          <Content>{recruit.content}</Content>
          <Adress><div>근무위치</div><div>{recruit.adress}</div></Adress>
          <Tel><div>접수 및 문의</div><div>{recruit.tel}</div></Tel>
        </div>
        : null
      ))}
      <BackButton onClick={()=>navigate("/recruit")}>목록으로 돌아가기</BackButton>
    </Container>
  )
}
export default Recruit;