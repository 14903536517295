
import styled from "styled-components";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

import { TopImg01, TopImg02, TopImg03, TopImg04, TopImg05, TopImgM01, TopImgM02, TopImgM03, TopImgM04, TopImgM05 } from "../assets/homePageImg/home_topImgList";
import { ReactComponent as CampassSvg } from "../assets/homePageImg/home_icon_campass.svg";
import CampassImg from "../assets/homePageImg/home_icon_campass.png";
import { ReactComponent as MessageSvg } from "../assets/homePageImg/home_icon_message.svg";
import BannerPng from "../assets/homePageImg/home_bannerImg_01.png";
import BannerPngM from "../assets/homePageImg/home_bannerImg_M01.png";
import { ReactComponent as CameraSvg } from "../assets/homePageImg/home_icon_camera.svg";
import { ReactComponent as Headphone } from "../assets/homePageImg/home_icon_headphone.svg";
import { HelpImg01, HelpImg02, HelpImg03, HelpImgM01, HelpImgM02, HelpImgM03 } from "../assets/homePageImg/home_helpImgList";
import HomeArticle from "./article/HomeArticle";

const Container = styled.main`
  width: 98vw;
  display: flex;
  flex-direction: column;
  row-gap: 161px;
  align-items: center;
  &>*{
    width: 100%;
  }
  @media (max-width: 1024px) {
    width: 100%;
    row-gap: 87px;
  }
  @media (max-width: 730px) {
    width: 91vw;
    row-gap: 11.9vw;
  }
`
const MainImgContainer = styled.article`
  height: 810px;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  animation: topImg 14s infinite linear;
  background-position-x: center;
  background-position-y: top;
  background-repeat: no-repeat;
  background-size: contain;
  background-clip: border-box;
  background-origin: border-box;
  @keyframes topImg {
    0%{background-image: url(${TopImg01});}
    14%{background-image: url(${TopImg01});}
    20%{background-image: url(${TopImg02});}
    34%{background-image: url(${TopImg02});}
    40%{background-image: url(${TopImg03});}
    54%{background-image: url(${TopImg03});}
    60%{background-image: url(${TopImg04});}
    74%{background-image: url(${TopImg04});}
    80%{background-image: url(${TopImg05});}
    94%{background-image: url(${TopImg05});}
    100%{background-image: url(${TopImg01});}
  }
  @media (max-width: 1024px) {
    height: 460px;
    width: 705px;
    animation: topImgM 14s infinite linear;
    @keyframes topImgM {
      0%{background-image: url(${TopImgM01});}
      14%{background-image: url(${TopImgM01});}
      20%{background-image: url(${TopImgM02});}
      34%{background-image: url(${TopImgM02});}
      40%{background-image: url(${TopImgM03});}
      54%{background-image: url(${TopImgM03});}
      60%{background-image: url(${TopImgM04});}
      74%{background-image: url(${TopImgM04});}
      80%{background-image: url(${TopImgM05});}
      94%{background-image: url(${TopImgM05});}
      100%{background-image: url(${TopImgM01});}
    }
  }
  @media (max-width: 730px) {
    height: 62.96vw;
    width: 91vw;
  }
`
const CampassBox = styled.div`
  height: 104px;
  padding-right: 2%;
  display: flex;
  align-self: flex-end;
  align-items: baseline;
  justify-content: right;
  &>svg,img{
    display: flex;
    align-items: baseline;
    height: 88px;
    width: 77px;
  }
  @media (max-width: 1024px) {
    padding-right: 1%;
  }
  @media (max-width: 730px) {
    padding-right: 0;
    &>svg,img{
      height: 12vw;
      width: 10.5vw;
    }
  }
`
const TopImgText = styled.div`
  padding: 0 136px 0 80px;
  color: white;
  font-size: 54px;
  font-weight: 400;
  letter-spacing: -.5px;
  &::after{
    content: "";
    animation: topImgText 14s infinite linear;
    @keyframes topImgText {
      0%{content: "교육을 통한 가치 실현";}
      14%{content: "교육을 통한 가치 실현";}
      20%{content: "장애문화예술인 양성 사업 [ 휠체어태평무 ]";}
      34%{content: "장애문화예술인 양성 사업 [ 휠체어태평무 ]";}
      40%{content: "경력보유여성 그림책 작가 양성 사업 [ 슈필라움북 ]";}
      54%{content: "경력보유여성 그림책 작가 양성 사업 [ 슈필라움북 ]";}
      60%{content: "미래인재양성 학생핵심역량 교육 [ Jump-up ]";}
      74%{content: "미래인재양성 학생핵심역량 교육 [ Jump-up ]";}
      80%{content: "여성 복합문화예술공간 브랜딩 [ 슈필라우미 ]";}
      94%{content: "여성 복합문화예술공간 브랜딩 [ 슈필라우미 ]";}
      100%{content: "교육을 통한 가치 실현";}
    }
  }
  @media (max-width: 1024px) {
    display: none;
  }
`
const ArticleContainer = styled.article`
  display: flex;
  flex-direction: column;
  row-gap: 53px;
  &>span{
    height: 537px;
  }
  @media (max-width: 1024px) {
    row-gap: 55px;
    &>span{height: 566px;}
  }
  @media (max-width: 730px) {
    row-gap: 7.5vw;
    &>span{height: 77vw;}
  }
`
const ArticleTitle = styled.h1`
  display: flex;
  column-gap: 22px;
  align-items: center;
  &>svg{
    height: 75px;
    width: 75px;
  }
  &>span{
    font-size: 54px;
    font-weight: 400;
  }
  @media (max-width: 1024px) {
    flex-direction: column;
    row-gap: 18px;
    align-items: baseline;
    &>svg{
      margin-left: 26px;
      height: 89px;
      width: 89px;
    }
    &>span{
      margin-left: 37px;
      white-space: pre;
      line-height: 65px;
      font-size: 50px;
      font-weight: 400;
    }
  }
  @media (max-width: 730px) {
    row-gap: 2.4vw;
    &>svg{
      margin-left: 1vw;
      height: 12.1vw;
      width: 12.1vw;
    }
    &>span{
      margin-left: 2vw;
      font-size: 6.8vw;
      line-height: 8vw;
    }
  }
`
const PostContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  overflow-x: hidden;
`
const MessagePostBox = styled.div`
  display: flex;
  justify-content: left;
  column-gap: 18px;
  animation: toLeft1 ${props=>props.className*2.5}s infinite linear;
  &:hover{
    animation-play-state: paused;
  }
  @keyframes toLeft1 {
    0%{transform: translateX(8vw);}
    100%{transform: translateX(-${props=>props.className*8}vw);}
  }
  @media (max-width: 1024px) {
    column-gap: 23px;
  }
  @media (max-width: 730px) {
    column-gap: 2.5vw;
  }
`
const CameraPostBox = styled.div`
  display: flex;
  justify-content: left;
  column-gap: 18px;
  animation: toLeft2 ${props=>props.className*2.5}s infinite linear;
  &:hover{
    animation-play-state: paused;
  }
  @keyframes toLeft2 {
    0%{transform: translateX(8vw);}
    100%{transform: translateX(-${props=>props.className*8}vw);}
  }
  @media (max-width: 1024px) {
    column-gap: 23px;
  }
  @media (max-width: 730px) {
    column-gap: 2.5vw;
  }
`
const StartEdu = styled.div`
  &>img{
    height: 482px;
    width: 98vw;
    border-radius: 24px;
  }
  &>:last-child{
    display: none;
  }
  @media (max-width: 1024px) {
    padding-top: 18px;
    display: flex;
    justify-content: center;
    &>img{
      height: 321px;
      width: 705px;
    }
    &>:first-child{
      display: none;
    }
    &>:last-child{
      display: flex;
    }
  }
  @media (max-width: 730px) {
    padding-top: 4vw;
    &>img{
      height: 43.9vw;
      width: 91vw;
    }
  }
`
const HeadphoneBox = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 23px;
  &>a>img{
    display: none;
  }
  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
    place-items: center;
    grid-gap: 3vw;
    &>a>svg{
      display: none;
    }
    &>a>img{
      display: block;
      border-radius: 24px;
    }
  }
  @media (max-width: 730px) {
    &>a>img{
      width: 91vw;
    }
  }
`

function Home() {
  const [isCarouselPage,setIsCarouselPage] = useState(1);
  const [isAllList,setIsAllList] = useState([]);
  const [isStoryList, setIsStoryList] = useState([]);

  useEffect(()=>{
    setTimeout(()=>{
      isCarouselPage<4
      ? setIsCarouselPage(prev=>prev+1)
      : setIsCarouselPage(1)
    },2500)
  },[isCarouselPage])

  useEffect(()=>{
    window.scrollTo(0,0);
    axios.get(process.env.REACT_APP_API_URL+"research")
    .then(res=>setIsAllList(res.data))
    // .catch(err=>alert("전체 데이터를 불러오지 못했습니다."))
    axios.get(process.env.REACT_APP_API_URL+"research/category/story")
    .then(res=>{
      setIsStoryList(res.data)
    })
    .catch(err=>{
      // alert("브랜딩 데이터를 불러오지 못했습니다.")
    })
  },[])

  return (
    <Container>

      <MainImgContainer>
        <CampassBox><img src={CampassImg} /></CampassBox>
        <TopImgText />
      </MainImgContainer>

      <ArticleContainer>
        <Link to="/"><ArticleTitle><MessageSvg /><span>더퍼스트에듀와 {"\n"}사람들의 이야기</span></ArticleTitle></Link>

        <span>
          <PostContainer>
            <MessagePostBox className={isStoryList.length}>
              {isStoryList.map(post=><HomeArticle category={post.category} post={post} />)}
            </MessagePostBox>
          </PostContainer>
        </span>
      </ArticleContainer>

      <StartEdu>
        <img src={BannerPng} />
        <img src={BannerPngM} />
      </StartEdu>

      <ArticleContainer>
        <ArticleTitle><CameraSvg /><span>문화예술 & 교육 & 브랜딩</span></ArticleTitle>

        <span>
          <PostContainer>
            <CameraPostBox className={isAllList.length}>
              {isAllList.map(post=><HomeArticle category={post.category} post={post} />)}
            </CameraPostBox>
          </PostContainer>
        </span>
      </ArticleContainer>

      <ArticleContainer>
        <ArticleTitle><Headphone /><span>무엇을 도와드릴까요?</span></ArticleTitle>
        <HeadphoneBox>
          <Link to="https://pf.kakao.com/_xlkZiG/chat" target="_blank"><HelpImg01 /></Link>
          <Link to="https://pf.kakao.com/_xlkZiG/chat" target="_blank"><HelpImg02 /></Link>
          <Link to="https://pf.kakao.com/_xlkZiG/chat" target="_blank"><HelpImg03 /></Link>
          <Link to="https://pf.kakao.com/_xlkZiG/chat" target="_blank"><img src={HelpImgM01} /></Link>
          <Link to="https://pf.kakao.com/_xlkZiG/chat" target="_blank"><img src={HelpImgM02} /></Link>
          <Link to="https://pf.kakao.com/_xlkZiG/chat" target="_blank"><img src={HelpImgM03} /></Link>
        </HeadphoneBox>
      </ArticleContainer>

    </Container>
  );
}

export default Home;